












import './TheProject.scss';
import './TheProjectAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProjectState } from '@store/modules/project/Interfaces';
import { ProjectActions, ProjectMutations } from '@store/modules/project/Types';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import {ProjectDocumentsActions, ProjectDocumentsMutations} from "@store/modules/project-documents/Types";
import {IDocument} from "@store/modules/project-documents/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSTasks = namespace('storeProjectTasks');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'TheProject',

    components: {
        'BaseSidebarOpenBtn': () => import('@components/BaseSidebar/BaseSidebarOpenButton.vue'),
    },
})
export default class ProjectsComponent extends Vue {
    @NSProject.Action(ProjectActions.A_GET_PROJECT) getProject!: (any) => Promise<void>
    @NSUser.Action(UserActionTypes.A_SET_USER_RIGHT_ONLY_GSN) setUserRightOnlyGSN!: (userId) => Promise<void>
    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.State((state: IProjectState) => state.projectLoaded) projectLoaded!: boolean;
    @NSProject.Mutation(ProjectMutations.M_TOGGLE_PROJECT_LOADED) toggleProjectLoaded!: (projectLoadedState: boolean) => void;
    @NSProject.Action(ProjectActions.A_GET_MART_KIT) getKitBrands!: () => Promise<void>
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_PROJECT_DOCUMENTS) setDocumentsInStore!: (docs: IDocument[]) => Promise<void>
    @NSTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) getProjectTasks!: (projectId: number) => Promise<void>;
    @NSTasks.Action(ProjectTasksActions.A_SET_PROJECT_TASKS_LOADED) setTasksLoaded!: (val: boolean) => Promise<void>;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_PROJECT_DOCUMENTS_LOADED) setDocumentsLoaded!: (loadedState: boolean) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOCUMENTS) getDocuments!: (appId: number) => Promise<void>;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSProject.Getter('projectId') projectId!: number;

    sectionTitle: string = this.$route.meta?.title || ' ';

    async mounted() {
        setTimeout(() => {
            this.updateViewingProject();
        }, 1000);
    }

    @Watch('$route.params.projectId')
    async watchProjectId() {
        await this.updateViewingProject();
    }

    @Watch('$route.path')
    watchRoutePath() {
        this.sectionTitle = this.$route.meta?.title;
    }

    async updateViewingProject() {
        const projectId = parseInt(this.$route.params.projectId, 10);
        this.toggleProjectLoaded(false);

        await this.getProject({ projectId: +projectId })
            .then(() => {
                this.setUserRightOnlyGSN(this.userData.userInfo.id);
                this.toggleProjectLoaded(true);
            })
            .finally(() => {
                this.getKitBrands();
                this.getProjectTasks(projectId).finally(() => {
                    this.setTasksLoaded(true);
                });

                if (!this.documents.length) {
                    this.setDocumentsLoaded(false);
                    this.getDocuments(projectId).then((data: any) => {
                        this.setDocumentsInStore(data).then(() => {
                            this.setDocumentsLoaded(true);
                        });
                    })
                }
            });

    }
}
